<template>
  <Hero :subtitle="data?.header.subtitle" :title="data?.header.title" :padded="true" :images="data?.header.images"/>
  <div class="overhanging">
    <div class="maxw--body grid--four">
      <div class="card text--center vertical-stretch">
        <h3 class="mb-2">Veel borrels en activiteiten</h3>
        <p v-if="!events || events.length < 1">Geen aankomende evenementen</p>
        <nuxt-link v-else :to="`/evenementen/${events[0].slug}`" class="link--flat">
          <h5 class="mb-1">{{ events[0].title }}</h5>
          <span class="text--medium text--block">{{ format(new Date(events[0].start), 'd MMMM yyyy') }}</span>
          <span class="text--medium mt-05 text--block">{{ format(new Date(events[0].start), 'HH:ss') }} - {{ format(new Date(events[0].end), 'HH:ss') }}</span>
        </nuxt-link>
        <nuxt-link class="btn mt-2" to="/evenementen">Evenementen</nuxt-link>
      </div>
      <div class="card text--center vertical-stretch">
        <h3 class="mb-2">Verbreed je netwerk</h3>
        <p class="par">
          <b>{{ data?.committees_text }}</b>
        </p>
        <p class="par">
          <template v-for="committee in committees" :key="committee.id">
            <span>{{ committee.title }}</span><br/>
          </template>
          
          <br v-if="clubs && committees && clubs.length > 0 && committees.length > 0"/>
          <template v-for="club in clubs" :key="club.id">
            <span>{{ club.title }}</span><br/>
          </template>
        </p>
        <nuxt-link class="btn mt-2" to="/over/clubs-commissies">Clubs & commissies</nuxt-link>
      </div>
      <div class="card text--center vertical-stretch">
        <h3 class="mb-2">Vrienden voor het leven</h3>
        <PhotoCollage :image_one="data?.image_highlight_one" :image_two="data?.image_highlight_two"/>
        <nuxt-link class="btn mt-2" to="/albums">Foto's</nuxt-link>
      </div>
      <div class="card text--center vertical-stretch">
        <h3 class="mb-2">Goedkoop bier</h3>
        <p class="par">
          <b>{{ data?.club_text }}</b>
        </p>
        <nuxt-link class="btn mt-2" to="/over/de-soos">Over de soos</nuxt-link>
      </div>
    </div>
  </div>
  <main class="main">
    <div class="block maxw--waist text--center" v-html="$md.render(data?.text || '')"></div>
    <div class="block maxw--body grid--two">
      <div class="card card--video">
        <Video :data="data?.video_highlight_one || '{}'"/>
      </div>
      <div class="card card--video">
        <Video :data="data?.video_highlight_two || '{}'"/>
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
import Event from '~/typings/event';
import HomePage from '~/typings/home_page';
import { format } from 'date-fns';
import Committee from '~/typings/committee';
import useCommittees from '~/composables/committees';

useHead({
  title: 'SV Virgo | De gezelligste studentenvereniging van Breda',
  titleTemplate: '%s'
});

const { findOne, find } = useStrapi();

const { data } = await useAsyncData(
  'homepage',
  () => findOne<HomePage>('home-page', { populate: ['header', 'header.images', 'image_highlight_one', 'image_highlight_two'] }),
  {
    transform: flattenStrapiObject
  }
);

const { data: events } = await useAsyncData(
  'firstevents',
  () => find<Event>('events', { 
    filters: { 
      start: {
        $gte: new Date()
      }
    },
    sort: 'start',
    pagination: {
      page: 1,
      pageSize: 1
    }
  }),
  {
    transform: flattenStrapiCollection
  }
);

const { data: committeesData } = await useAsyncData(
  'committees',
  () => find<Committee>('committees'),
  {
    transform: flattenStrapiCollection
  }
);

const { committees, clubs } = useCommittees(committeesData);
</script>
